import { Box, SelectChangeEvent, InputAdornment, IconButton } from "@mui/material";
import { CancelIcon, SearchIcon, SelectField, Typography } from "@uy3/web-components";
import { FunctionComponent } from "react";
import { activeTheme } from "services/theme";
import { useFormContext } from "context/form/formContext";
import { AppUtils } from "utils/appUtils";
import { SelectFormFieldProps } from "./SelectFormField.interface";

const theme = activeTheme();

export const SelectFormField: FunctionComponent<SelectFormFieldProps> = ({
   options,
   required,
   label,
   ...props
}) => {
   const { name, multiple } = props;

   const labels: string[] | undefined = options?.length
      ? options.map((i) => i?.label)
      : ["Nenhuma opção encontrada"];

   let value = undefined;
   let { validationErrors, setValue, watch, readOnly } = useFormContext();

   let error = validationErrors && AppUtils.resolve(name, validationErrors)?.message;

   if (watch) {
      const currentValue = watch(name);
      if (multiple) {
         const selectedItems = options?.filter((item) => currentValue?.includes(item?.value));
         const selectedLabels = selectedItems?.map((item) => item.label);
         value = selectedLabels ?? undefined;
      } else {
         const foundItem = options?.find((item) => item.value === currentValue);
         value = foundItem?.label ?? undefined;
      }
   }

   const onChange = (event: SelectChangeEvent<unknown>) => {
      if (multiple && options?.length) {
         const selectedOptions = event.target?.value as string[];
         const selectedValues: any[] = [];
         const selectedLabels: any[] = [];

         selectedOptions.forEach((val) => {
            const index = options.findIndex((i) => i?.label === val);
            if (index !== -1) {
               selectedValues.push(options[index]?.value);
               selectedLabels.push(options[index]?.label);
            }
         });
         setValue(name, selectedValues, { shouldDirty: true });
         setValue(`${name}Display`, selectedLabels, { shouldDirty: true });
      } else if (options?.length) {
         const value = event.target?.value as string;
         const index = options.findIndex((i) => i?.label === value);
         setValue(name, options[index]?.value, { shouldDirty: true });
         setValue(`${name}Display`, options[index]?.label, { shouldDirty: true });
      }
   };

   const isRequired = !!required;
   const labelWithRequired = isRequired ? `${label} *` : label;
   const shrink = watch(name) ? true : false;

   return (
      <Box>
         <SelectField
            id={name}
            label={labelWithRequired}
            inputProps={{
               "aria-label": "secondary select",
               shrink
            }}
            endAdornment={
               <>
                  {props.showEndAdornment && (
                     <InputAdornment position="end" sx={{ marginRight: 1.5 }}>
                        <IconButton type="submit">
                           <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                        </IconButton>
                     </InputAdornment>
                  )}
                  {props.showButtonClearValue && (
                     <>
                        {!!value && !readOnly && (
                           <InputAdornment position="end" sx={{ marginRight: 1.5 }}>
                              <IconButton onClick={() => setValue(name, null)}>
                                 <CancelIcon sx={{ width: 15, height: 15 }} htmlColor="#666666" />
                              </IconButton>
                           </InputAdornment>
                        )}
                     </>
                  )}
               </>
            }
            key={`${name}${value}key`}
            error={!!error}
            onChange={onChange}
            items={labels}
            value={value === undefined ? "" : value}
            disabled={!!readOnly}
            MenuProps={{
               PaperProps: {
                  onKeyDown: (event: any) => {
                     if (event.key === "Enter") {
                        AppUtils.sendSubmitEvent(event);
                     }
                  }
               }
            }}
            {...props}
         />
         {!!error && (
            <Typography mt={0.5} variant="body1" color={theme.palette.error.dark}>
               <>{error}</>
            </Typography>
         )}
      </Box>
   );
};
