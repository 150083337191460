import axios from "axios";
import { IConfigs } from "./config.interface";
import ConfigJson from "./config.json";

export async function loadConfig(domain: string) {
   const localConfig = localStorage.localConfig;

   let config: Object = {};
   if (localConfig === "local") {
      config = ConfigJson as Object;
   } else if (localConfig === undefined) {
      const resp = await axios.get(
         `https://uyzy-config.s3.sa-east-1.amazonaws.com/onboarding-contas/${domain}.json`
      );
      config = resp.data;
   } else {
      const resp = await axios.get(
         `https://uyzy-config.s3.sa-east-1.amazonaws.com/onboarding-contas/${localConfig}.json`
      );
      config = resp.data;
   }

   saveConfig(config);
   return activeConfig();
}

export function activeConfig(): IConfigs {
   let config: any = {};
   for (var key in localStorage) {
      if (key.indexOf("ONBOARD_") > -1) {
         var item = localStorage.getItem(key);
         var configKey = key.replace("ONBOARD_", "");
         if (item) config[configKey] = JSON.parse(item);
      }
   }
   return config as IConfigs;
}

export function saveConfig(config: any) {
   for (var key in config) {
      localStorage.setItem(`ONBOARD_${key}`, JSON.stringify(config[key as keyof IConfigs]));
   }
   localStorage.setItem("ONBOARD_LOADED", JSON.stringify(true));
}
