import { Box, Typography } from "@mui/material";
import { Checkbox } from "@uy3/web-components";
import { useFormContext } from "context/form/formContext";
import { FunctionComponent } from "react";
import { activeTheme } from "services/theme";
import { AppUtils } from "utils/appUtils";
import { CheckboxFormFieldProps } from "./CheckboxFormField.interface";

const theme = activeTheme();
export const CheckboxFormField: FunctionComponent<CheckboxFormFieldProps> = ({ ...props }) => {
   const name = props.name;
   let value = undefined;
   let { validationErrors, watch, setValue, readOnly } = useFormContext();

   if (watch) {
      value = watch(name);
   }

   const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.checked;
      setValue(name, newValue, { shouldDirty: true });
   };

   let error = validationErrors && AppUtils.resolve(name, validationErrors)?.message;

   return (
      <Box mt={"8px"}>
         <Checkbox
            onChange={onChange}
            checked={value === true}
            disabled={readOnly}
            inputProps={{
               "aria-label": "secondary checkbox"
            }}
            {...props}
         />
         {!!error && (
            <Typography mt={1} mb={2} variant="body1" color={theme.palette.error.dark}>
               <>{error}</>
            </Typography>
         )}
      </Box>
   );
};
