import { Stack } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "./Slider.scss";
import { Typography } from "@uy3/web-components";

export interface Image {
   img: string;
   description: string;
}

interface SliderProps {
   images: Image[];
}

const settings = {
   dots: true,
   infinite: false,
   speed: 500,
   autoPlay: true,
   slidesToShow: 1,
   slidesToScroll: 1,
   arrows: false
};

export const CustomSlider: React.FC<SliderProps> = ({ images }) => {
   return (
      <div className="slider-container">
         <Slider {...settings}>
            {images.map((image, index) => (
               <Stack key={index} direction="row" alignItems="center" spacing={2}>
                  <div className="slideImg">
                     <img src={image.img} alt={image.description} />
                  </div>
                  <div className="description">
                     <Typography variant="lg" color="text.secondary">
                        {image.description}
                     </Typography>
                  </div>
               </Stack>
            ))}
         </Slider>
      </div>
   );
};
