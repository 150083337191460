import { Typography, TextField } from "@uy3/web-components";
import { FunctionComponent, memo, useState } from "react";
import { activeTheme } from "services/theme";
import { Controller } from "react-hook-form";
import { useFormContext } from "context/form/formContext";
import { AppUtils } from "utils/appUtils";
import { TextFormFieldProps } from "./TextFormField.interface";

const theme = activeTheme();

//Lets you skip re-rendering a component when its props are unchanged.
export const TextFormField: FunctionComponent<TextFormFieldProps> = memo(function TextFormField({
   name,
   label,
   required,
   ...props
}) {
   let { validationErrors, readOnly, control, watch } = useFormContext();
   let error = validationErrors && AppUtils.resolve(name, validationErrors)?.message;

   const isRequired = !!required;
   const labelWithRequired = isRequired ? `${label} *` : label;

   const currentValue = watch(name);
   const hasInputProps = !!props?.InputProps?.endAdornment || !!props?.InputProps?.startAdornment;
   const isValidValue = currentValue != null && currentValue !== "" && currentValue !== undefined;
   const shouldShrinkLabel = isValidValue || (hasInputProps && isValidValue);
   const [shrink, setShrink] = useState(shouldShrinkLabel);

   const handleOnFocus = () => setShrink(true);
   const unShrinkLabel = () => setShrink(false);

   return (
      <>
         <Controller
            render={({ field }) => (
               <TextField
                  {...field}
                  {...props}
                  id={name}
                  error={!!error}
                  label={labelWithRequired}
                  fullWidth
                  disabled={readOnly || props.disabled}
                  variant="outlined"
                  onFocus={handleOnFocus}
                  onBlur={unShrinkLabel}
                  InputLabelProps={{ shrink: shrink || shouldShrinkLabel }}
               />
            )}
            name={name}
            control={control}
         />
         {!!error && (
            <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
               <>{error}</>
            </Typography>
         )}
      </>
   );
});
