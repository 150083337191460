import { Toast as ToastUI } from "@uy3/web-components";
import { ToastType } from "utils/appUtils/showToast";

interface ToastProps {
   toast: ToastType;
   setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

export function Toast({ toast, setToast, ...props }: ToastProps) {
   return (
      <ToastUI
         {...props}
         open={!!toast.open}
         onClose={() => setToast({ ...toast, open: false })}
         title={toast?.title}
         description={toast?.description}
         severity={toast?.severity}
      />
   );
}
