import {
   InvalidateQueryFilters,
   QueryClient,
   QueryKey,
   RefetchQueryFilters
} from "@tanstack/react-query";
import { createContext, useContext, useState } from "react";
import {
   ApiRequestContextProps,
   ApiRequestProviderProps,
   ApiResponseError
} from "./apiRequest.interface";

export const invalidateAndRefetch = async (queryClient: QueryClient, queryKey: QueryKey) => {
   await queryClient.invalidateQueries(queryKey as InvalidateQueryFilters);
   await queryClient.refetchQueries(queryKey as RefetchQueryFilters);
};

export const iconSx = { width: 20, height: 20 };

const ApiRequestContext = createContext<ApiRequestContextProps>({
   submitting: false,
   startRequest: () => {
      throw Error("UserContext not initialized");
   },
   endRequest: () => {
      throw Error("UserContext not initialized");
   },
   submitError: undefined,
   setSubmitError: () => {
      throw Error("UserContext not initialized");
   }
});

export const ApiRequestProvider = ({ children }: ApiRequestProviderProps) => {
   const [submitting, setsubmitting] = useState(false);
   const [submitError, setSubmitError] = useState<ApiResponseError | undefined>(undefined);

   return (
      <ApiRequestContext.Provider
         value={{
            submitting,
            startRequest: () => setsubmitting(true),
            endRequest: (success: boolean) => {
               setsubmitting(false);
               if (success && submitError) {
                  setSubmitError(undefined);
               }
            },
            submitError,
            setSubmitError
         }}
      >
         {children}
      </ApiRequestContext.Provider>
   );
};

export function useApiRequest(): ApiRequestContextProps {
   const context = useContext(ApiRequestContext);
   const { submitting, startRequest, endRequest, submitError, setSubmitError } = context;

   return {
      submitting,
      startRequest,
      endRequest,
      submitError,
      setSubmitError
   };
}
