import { activeConfig } from "services/config";

const configs = activeConfig();

export const socialList = configs?.SOCIAL_MEDIA
   ? configs?.SOCIAL_MEDIA
   : [
        {
           icon: "facebook-f",
           title: "Facebook",
           url: "#"
        },
        {
           icon: "instagram-in",
           title: "Instagram",
           url: "#"
        },
        {
           icon: "linkedin-in",
           title: "Linkedin",
           url: "#"
        },
        {
           icon: "envelop",
           title: "Email",
           url: "#"
        },
        {
           icon: "youtube",
           title: "Youtube",
           url: "#"
        },
        {
           icon: "twitter",
           title: "X",
           url: "#"
        }
     ];
