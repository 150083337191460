import { FunctionComponent } from "react";
import { UploadProps } from "./Upload.interface";
import rgFrente from "assets/images/rgFrente-vertical.png";
import rgBack from "assets/images/rgFundo-vertical.png";
import cnhFrente from "assets/images/cnhFrente-horizontal.png";
import cnhBack from "assets/images/cnhFundo-horizontal.png";
import uploadIcon from "assets/images/upload.png";
import UploadDropzone from "./UploadDrop/UploadDrop";
import { Grid, Stack } from "@mui/material";
import { Typography } from "@uy3/web-components";
import { activeTheme } from "services/theme";
import "./Upload.scss";

export const Upload: FunctionComponent<UploadProps> = (props) => {
   const { hasImage } = props;

   return (
      <Stack
         direction={"row"}
         spacing={2}
         alignItems="center"
         display="flex"
         justifyContent="center"
      >
         {hasImage && (
            <Grid
               container
               spacing={1}
               gap={5}
               alignItems="center"
               display="flex"
               justifyContent="center"
            >
               <Grid item xs={12} md={5} bgcolor={"#f5f5f5"} borderRadius={"11px"}>
                  <UploadComponent {...props} />
               </Grid>
               <Grid item xs={12} md={5.5} borderRadius={"11px"} border={"1px solid #cccdd0"}>
                  <DocumentImage {...props} />
               </Grid>
            </Grid>
         )}

         {!hasImage && (
            <Grid bgcolor={"#f5f5f5"} borderRadius={"11px"} width="100%">
               <UploadComponent {...props} />
            </Grid>
         )}
      </Stack>
   );
};

export default Upload;

const DocumentImage: FunctionComponent<UploadProps> = (props) => {
   const { upload, img } = props;
   const imgs: { [key: string]: string } = {
      rgFundo: rgBack,
      rgFrente: rgFrente,
      cnhFrente: cnhFrente,
      cnhFundo: cnhBack
   };

   let imgSrc = imgs[upload?.img!];

   return (
      <div className="styledDocumentImage">
         <img src={imgSrc} alt="Ícone de Upload" />
         <h4>{img?.title}</h4>
      </div>
   );
};

const theme = activeTheme();
const UploadComponent: FunctionComponent<UploadProps> = (props) => {
   const { accept, title, errorMessage, upload } = props;
   const displayName = upload?.displayName;

   return (
      <Stack spacing={2} my={6} display="flex" alignItems="center" justifyContent="center">
         <Grid>
            <img src={uploadIcon} alt="Ícone de Upload" />
         </Grid>
         <UploadDropzone accept={accept!} />
         <Typography variant="lg" sx={{ color: "#666666" }}>
            {title}
         </Typography>
         <Typography variant="body1" mt={0.5} color={theme.palette.primary.main}>
            {displayName}
         </Typography>
         <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
            {errorMessage}
         </Typography>
      </Stack>
   );
};
