import React, { createContext, useContext, useState } from "react";
import { FieldValues } from "react-hook-form";

type FormValuesContextProps = {
   formValues: {} | undefined;
   setFormValues: (values: FieldValues | undefined) => void;
};

interface FormValuesProviderProps {
   children: JSX.Element | JSX.Element[];
}

const FormValuesContext = createContext<FormValuesContextProps>({
   formValues: undefined,
   setFormValues: () => {}
});

export const FormValuesProvider: React.FC<FormValuesProviderProps> = ({ children }) => {
   const [formValues, setFormValues] = useState<FieldValues | undefined>();

   return (
      <FormValuesContext.Provider
         value={{
            formValues,
            setFormValues
         }}
      >
         {children}
      </FormValuesContext.Provider>
   );
};

export const useFormValuesContext = () => useContext(FormValuesContext);
