import { Autocomplete, AutocompleteProps } from "components/Forms/Autocomplete";
import { Typography } from "@uy3/web-components";
import { activeTheme } from "services/theme";
import { useFormContext } from "context/form/formContext";
import { AppUtils } from "utils/appUtils";

const theme = activeTheme();

type AutocompleteFieldProps = {
   name: string;
   displayName?: string;
} & AutocompleteProps;

export default function AutocompleteField(props: AutocompleteFieldProps) {
   const { name, displayName = "" } = props;
   const { validationErrors, watch, setValue, readOnly } = useFormContext();
   let error = validationErrors && AppUtils.resolve(name, validationErrors)?.message;

   const userOnChange = props.onChange;
   const onChange = (_: any, opt: any) => {
      setValue(name, opt?.value, { shouldDirty: true });
      setValue(displayName, opt?.label, { shouldDirty: true });

      let nameWithoutId = name?.replace("Id", "");
      if (nameWithoutId)
         setValue(`${nameWithoutId}Discriminator`, opt?.discriminator, { shouldDirty: true });
      userOnChange && userOnChange(_, opt);
   };

   const autoCompleteValue = { label: watch(displayName), value: watch(name) };

   return (
      <div>
         <Autocomplete
            disabled={readOnly}
            value={autoCompleteValue}
            error={error?.length > 0 ? true : false}
            {...props}
            onChange={onChange}
         />
         {!!error && (
            <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
               {error}
            </Typography>
         )}
      </div>
   );
}
