import { Typography } from "@uy3/web-components";
import { CustomGrid } from "components/Custom/CustomGrid";
import { Radio } from "components/Forms";
import { activeTheme } from "services/theme";
import { CardRadioProps } from "./CardRadio.interface";

const theme = activeTheme();

export const CardRadio: React.FC<CardRadioProps> = ({ name, title, onChange, value }) => {
   const BorderColorNP = value === name ? theme.palette.primary.main : theme.palette.grey[300];
   return (
      <CustomGrid container borderColor={BorderColorNP}>
         <Typography
            variant="md"
            color={value === name ? theme.palette.primary.main : theme.palette.common.black}
         >
            {title}
         </Typography>
         <Radio
            size="medium"
            value={value === name ? value : ""}
            options={[{ value: name }]}
            onChange={onChange}
         />
      </CustomGrid>
   );
};
