import React, { useCallback } from "react";
import {
   AutocompleteChangeReason,
   AutocompleteRenderInputParams,
   FilterOptionsState
} from "@mui/material";
import { Typography, AutocompleteField } from "@uy3/web-components";
import { debounce } from "@mui/material/utils";
import { SelectOption } from "../FormFields";
import { AutocompleteProps } from "./Autocomplete.interface";
import { isValidNumericString } from "utils/appUtils";

export const Autocomplete: React.FC<AutocompleteProps> = ({
   error = false,
   label,
   listOptions,
   onChange,
   required,
   value,
   onSearch,
   loading,
   link = "",
   endIconType,
   showEndIcon = false,
   disabled = false,
   multiple = false,
   ...props
}) => {
   const [open, setOpen] = React.useState(false);
   const [lastSearch, setLastSearch] = React.useState<string | undefined>(undefined);
   const currentValue = value;

   // Permite limpar a opção selecionada e a busca atual
   const wrappedOnChange = (ev: any, option: SelectOption, reason: AutocompleteChangeReason) => {
      if (reason === "clear") {
         setLastSearch(undefined);
         onSearch && onSearch(" ");
      }
      if (reason === "selectOption") {
         setLastSearch(option.label);
      }
      onChange && onChange(ev, option, reason);
   };

   const onFilterOptions = (options: any[], state: FilterOptionsState<any>) => {
      const regex: RegExp = /[()/\\]/g;
      return options?.filter((option: { label: string }) => {
         const search = state?.inputValue?.toLowerCase();
         const label = option?.label?.toLowerCase()?.replace(regex, "")?.replace("  ", " ");
         if (isValidNumericString(search)) return label?.replace(/\D/g, "")?.indexOf(search) > -1;
         return label?.indexOf(search) > -1;
      });
   };

   // Só realiza a busca após 500ms do término do preenchimento
   const debouncedOnSearch = useCallback(
      debounce((name) => {
         onSearch && onSearch(name);
      }, 1000),
      [value]
   );

   // A lista de opções não contém nenhum item mas houve uma busca por parte do usuário
   const searchNoResults = listOptions.length === 0 && !!lastSearch;

   // Após a busca, 0 resultados encontrados. Limpa o valor do form atual
   if (!!value?.value && searchNoResults) {
      onChange && onChange(undefined, undefined, undefined);
   }

   // Nenhum valor selecionado ou resultado disponível então usa a busca do usuário como label do campo
   if (lastSearch && (!!!value?.label || searchNoResults)) {
      if (value && value.label !== lastSearch) {
         value.label = lastSearch;
      } else if (!value) {
         value = {
            label: lastSearch
         };
      }
   }

   if (lastSearch?.length) {
      if (value) {
         value.label = lastSearch;
      } else {
         value = {
            label: lastSearch
         };
      }
   }

   if (currentValue?.value?.length > 0) {
      loading = false;
   }

   const isRequired = !!required;
   const labelWithRequired = isRequired ? `${label} *` : label;

   const sx = multiple
      ? {
           ".MuiAutocomplete-inputRoot": { paddingTop: "3px" }
        }
      : {
           ".MuiAutocomplete-inputRoot": { paddingTop: 0 }
        };

   return (
      <AutocompleteField
         filterOptions={onFilterOptions}
         error={error}
         multiple={multiple}
         sx={sx}
         label={labelWithRequired}
         open={open}
         disabled={disabled}
         onOpen={() => {
            setOpen(true);
         }}
         onClose={() => {
            setOpen(false);
         }}
         isOptionEqualToValue={(option, value) => option?.label === value?.label}
         getOptionLabel={(option) => option?.label ?? ""}
         renderOption={(props: object, option: any) => (
            <Typography key={lastSearch} {...props} variant="h6" fontFamily={'"Lato", sans-serif'}>
               {option.label}
            </Typography>
         )}
         options={listOptions}
         loading={loading}
         value={value}
         onChange={wrappedOnChange}
         link={link}
         endIconType={endIconType}
         showEndIcon={showEndIcon}
         onChangeTextField={(ev) => {
            debouncedOnSearch(ev.target.value);
            const currentValue = ev.target.value;
            if (lastSearch !== currentValue) {
               setLastSearch(currentValue);
            }
         }}
         renderInput={function (params: AutocompleteRenderInputParams): React.ReactNode {
            throw new Error("Function not implemented.");
         }}
         {...props}
      />
   );
};
