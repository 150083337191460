import React from "react";
import { ContainerProps } from "./Container.interface";
import "./Container.scss";
import { Grid, Paper } from "@mui/material";
import Header from "components/Header";
import Logout from "components/Logout";
import { useLocation } from "react-router";
import Steps from "components/Steps";
import { HelpButton } from "components/HelpButton";

export const Container: React.FC<ContainerProps> = ({ leftContent, rightContent }) => {
   const { pathname } = useLocation();
   const pathSegments = pathname.split("/");
   const currentPath = pathSegments[pathSegments?.length - 2] || "dados-empresa";

   const steps: { [key: string]: number } = {
      "dados-empresa": 0,
      "dados-socios": 1
   };

   const excludedPaths = [
      "sign-up",
      "login",
      "recuperar-senha",
      "account-confirmation",
      "finalizar-solicitacao",
      "retomar-solicitacao"
   ];

   const pathPop = pathSegments.pop() ?? "";
   const isSignUpEnabled = !(pathname === "/" || excludedPaths.includes(pathPop));

   return (
      <Grid container spacing={2}>
         <Grid item xs={6.5}>
            <Paper className="left-content">
               <Header />
               {/* {isSignUpEnabled && <Steps stepsNumber={steps[currentPath]} />} */}
               {leftContent}
               {isSignUpEnabled && <Logout />}
            </Paper>
         </Grid>
         <Grid item xs={12} lg={5}>
            <Paper elevation={0} className="right-content">
               <HelpButton />
               {rightContent}
            </Paper>
         </Grid>
      </Grid>
   );
};
