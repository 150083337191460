import { Typography } from "@uy3/web-components";
import "./Typography.scss";

export function Title({ title }: { title: string }) {
   return (
      <div className="typographyContainer">
         <Typography className="title">{title}</Typography>
      </div>
   );
}
