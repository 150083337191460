import { ToastProps } from "@uy3/web-components";

export type ToastType = {
   title: string;
   description?: string;
   severity: ToastProps["severity"];
   open: boolean;
};

export const toastState = {
   title: "",
   description: undefined,
   severity: undefined,
   open: false
};

export default class ShowToast {
   static showToast = (
      title: string,
      description: string | undefined,
      severity: ToastProps["severity"],
      setToast: React.Dispatch<React.SetStateAction<ToastType>>
   ) => {
      setToast({
         open: true,
         title,
         description,
         severity
      });
   };

   static showWarningToast = (
      title: string,
      description: string,
      setToast: React.Dispatch<React.SetStateAction<ToastType>>
   ) => {
      ShowToast.showToast(title, description, "warning", setToast);
   };

   static showSuccessToast = (
      title: string,
      description: string | undefined,
      setToast: React.Dispatch<React.SetStateAction<ToastType>>
   ) => {
      ShowToast.showToast(title, description, "success", setToast);
   };

   static showErrorToast = (
      title: string,
      description: string,
      setToast: React.Dispatch<React.SetStateAction<ToastType>>
   ) => {
      ShowToast.showToast(title, description, "error", setToast);
   };
}
