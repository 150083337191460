import { InputAdornment } from "@mui/material";
import { PercentageIcon, TextField, Typography } from "@uy3/web-components";
import React, { useState } from "react";
import { activeTheme } from "services/theme";
import { NumericFormat, InputAttributes, NumericFormatProps } from "react-number-format";
import { ICustomProps, IPercentageFormFieldProps } from "./PercentageFormField.interface";
import { useFormContext } from "context/form/formContext";
import { AppUtils, FormatCurrency } from "utils/appUtils";

const theme = activeTheme();
const NumberFormatCustom = React.forwardRef<NumericFormatProps<InputAttributes>, ICustomProps>(
   function NumberFormatCustom(props, ref) {
      const { onChange, ...other } = props;

      return (
         <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values: any) => {
               onChange({
                  target: {
                     name: props.name,
                     value: values.value
                  }
               });
            }}
            decimalSeparator=","
            valueIsNumericString
            decimalScale={6}
            fixedDecimalScale={true}
            maxLength={10}
         />
      );
   }
);

const PercentageFormField: React.FC<IPercentageFormFieldProps> = ({
   format = "decimal",
   name,
   required,
   label,
   ...props
}) => {
   const [keyboard, setKeyboard] = useState<string | undefined>(undefined);
   let { validationErrors, watch, setValue, readOnly } = useFormContext();

   let value = undefined;
   if (watch) {
      value = watch(name);
      if (value && format === "decimal") {
         value = FormatCurrency.formatMultiply(value);
      }
   }

   let error = validationErrors && AppUtils.resolve(name, validationErrors)?.message;

   const handleChange = (value: any) => {
      let newValue: string | undefined;
      setKeyboard(value);

      if (value !== "") {
         const parsedValue = Number(value);
         if (format === "decimal" && !isNaN(parsedValue)) {
            newValue = FormatCurrency.formatDivide(parsedValue)?.toString();
         } else if (format === "integer") {
            newValue = value;
         }
      } else {
         newValue = undefined;
      }

      setValue(name, newValue, { shouldDirty: true });
   };

   const isRequired = !!required;
   const labelWithRequired = isRequired ? `${label} *` : label;
   const currentValue = watch(name);

   const hasInputProps = !!props?.InputProps?.endAdornment || !!props?.InputProps?.startAdornment;
   const isValidValue = currentValue != null && currentValue !== "" && currentValue !== undefined;
   const shouldShrinkLabel = isValidValue || (hasInputProps && isValidValue);
   const [shrink, setShrink] = useState(shouldShrinkLabel);

   const handleOnFocus = () => setShrink(true);
   const unShrinkLabel = () => setShrink(false);

   return (
      <>
         <TextField
            {...props}
            name={name}
            error={!!error}
            disabled={readOnly}
            variant="outlined"
            value={value ?? keyboard}
            label={labelWithRequired}
            onChange={(event) => handleChange(event.target.value)}
            InputProps={{
               inputComponent: NumberFormatCustom as any,
               startAdornment: (
                  <InputAdornment position="start">
                     <PercentageIcon
                        sx={{ height: "1.7rem" }}
                        htmlColor={readOnly ? "#9E9E9E" : theme.palette.common.black}
                     />
                  </InputAdornment>
               )
            }}
            onFocus={handleOnFocus}
            onBlur={unShrinkLabel}
            InputLabelProps={{ shrink: shrink || shouldShrinkLabel }}
         />

         {!!error && (
            <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
               <>{error}</>
            </Typography>
         )}
      </>
   );
};

export default PercentageFormField;
