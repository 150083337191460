import { AxiosResponse } from "axios";
import { ApiResponseError } from "context/apiRequestContext/apiRequest.interface";
import axiosApi from "services/axios-api";
import { UploadNew } from "./IUpload.interface";

export async function getSignedUrl(
   fileName: string,
   token: string
): Promise<AxiosResponse<UploadNew, ApiResponseError>> {
   var url = `/Upload/${encodeURI(fileName)}`;
   const config = {
      headers: { Authorization: `Bearer ${token}` }
   };
   return await axiosApi.get(url, config);
}

export async function uploadFile(file: any, url: string) {
   var fileName = new URL(url).pathname.split("/").reverse()[0];

   var reader = new FileReader();
   reader.readAsArrayBuffer(file);

   const config = {
      headers: {
         "Content-Type": file.type || "application/octet-stream",
         "Content-Disposition": `inline; filename="${fileName}"`
      }
   };

   reader.onload = function (event: any) {
      return axiosApi.put(url, event.target.result, config);
   };
}
