import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import {
   FormValueUserProps,
   AuthenticationResponse
} from "services/user/identity/identity.interface";
import {
   currentUser,
   signIn as cognitoSign,
   changePassword as cognitoChangePassword,
   verifyEmail,
   resendEmailConfirmCode,
   confirmForgotPassword,
   forgotPassword
} from "services/user/identity";
import { useFormValuesContext } from "context/form/formValues";
import { AWSError } from "aws-sdk";
import { useApiRequest } from "context/apiRequestContext";
import ShowToast, { ToastType } from "utils/appUtils/showToast";

export interface User {
   id?: string;
   name?: string;
   verified?: boolean;
}

type UserContextProps = {
   user?: User | undefined;
   token: string | undefined;
   authFlow: AuthenticationResponse | undefined;
   submitError: string | undefined;
   clearSubmitError: () => void;
   logout: () => void;
   signIn: (email: string, password: string) => Promise<AuthenticationResponse>;
   isAuthenticated: () => boolean;
   navigateToFlow: (
      navigate: NavigateFunction,
      onError?: (value: React.SetStateAction<string | undefined>) => void
   ) => void;
   changePassword: (password: string, oldPassword: string) => {};
   resendEmailConfirmationCode: (setToast: React.Dispatch<React.SetStateAction<ToastType>>) => void;
   confirmEmailCode: (
      code: string,
      setToast: React.Dispatch<React.SetStateAction<ToastType>>
   ) => void;
   sendCodeForPasswordRecover: (email: string) => void;
   confirmPasswordRecover: (
      email: string,
      code: string,
      password: string
   ) => Promise<AuthenticationResponse>;
   setIsSignedIn: Dispatch<SetStateAction<boolean>>;
};

const UserContext = createContext<UserContextProps>({
   user: undefined,
   token: undefined,
   authFlow: undefined,
   submitError: undefined,
   logout: () => undefined,
   clearSubmitError: () => undefined,
   isAuthenticated: () => false,
   navigateToFlow: () => undefined,
   signIn: () => {
      throw Error("UserContext not initialized");
   },
   changePassword: () => {
      throw Error("UserContext not initialized");
   },
   resendEmailConfirmationCode: () => undefined,
   confirmEmailCode: () => {
      throw Error("UserContext not initialized");
   },
   sendCodeForPasswordRecover: () => undefined,
   confirmPasswordRecover: () => {
      throw Error("UserContext not initialized");
   },
   setIsSignedIn: () => undefined
});

interface IdentityProviderProps {
   children: JSX.Element;
}

const mapCurrentUser = () => {
   var cur = currentUser();
   if (cur) {
      return { id: cur.getUsername() };
   }
   return undefined;
};

const loadTokenAndVerifiedAttrs = (user: any, setUser: any, setToken: any) => {
   const cur = currentUser();
   cur?.getSession((error: Error | null, session: CognitoUserSession | null) => {
      if (error || !session?.isValid()) {
         console.log("UserContext - loadTokenAndVerifiedAttrs - getSession error", error);
         setUser(undefined);
      }
      let sessToken = session?.getAccessToken().getJwtToken();
      setToken(sessToken);
   });

   cur?.getUserAttributes((err, result) => {
      if (err) {
         console.log("UserContext - loadTokenAndVerifiedAttrs - error on getUserAttributes", err);
      }

      if (!user || user?.verified !== true) {
         var verifiedAttrs =
            result?.filter((attr) => attr.getName().endsWith("_verified") && !!attr.getValue()) ??
            [];
         var name = result
            ?.find((attr) => attr.getName().endsWith("name") && !!attr.getValue())
            ?.getValue();

         var id = result
            ?.find((attr) => attr.getName().endsWith("sub") && !!attr.getValue())
            ?.getValue();

         setUser((prevUser: any) => {
            return { ...prevUser, name, verified: verifiedAttrs?.length > 0, id };
         });
      }
   });
};

export const IdentityProvider = ({ children }: IdentityProviderProps) => {
   const [user, setUser] = useState<User | undefined>(mapCurrentUser());
   const [authFlow, setAuthFlow] = useState<AuthenticationResponse | undefined>();
   const [token, setToken] = useState<string | undefined>(undefined);
   const [submitError, setSubmitError] = useState<string | undefined>(undefined);
   const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
   const { formValues } = useFormValuesContext();
   const { startRequest, endRequest } = useApiRequest();
   const navigate = useNavigate();

   useEffect(() => {
      console.log("initial token load");
      loadTokenAndVerifiedAttrs(undefined, setUser, setToken);
   }, []);

   useEffect(() => {
      const cur = currentUser();

      const interval = setInterval(() => {
         if (token) {
            cur?.getSession((sessionError: Error | null, session: CognitoUserSession | null) => {
               if (sessionError) {
                  logout();
               }

               var maybeNewToken = session?.getAccessToken().getJwtToken();
               if (token !== maybeNewToken) {
                  setToken(maybeNewToken);
               }
            });
         } else {
            console.log("identity context - token not available");
         }
      }, 3 * 1000);

      return () => {
         clearInterval(interval);
      };
   }, [token]);

   useEffect(() => {
      if (authFlow?.type === "success") {
         console.log("IdentityProvider - loading token for authFlow", { authFlow });
         loadTokenAndVerifiedAttrs(user, setUser, setToken);
      }
      if (authFlow?.type === "failure") {
         console.log("IdentityProvider - authFlow failure", {
            details: authFlow.failureDetails
         });
         setSubmitError(authFlow?.failureDetails?.message);
      }
   }, [authFlow, user]);

   const isAuthenticated = () => (user && (!authFlow || authFlow?.type === "success")) === true;

   const signIn = async (email: string, password: string) => {
      var resp = await cognitoSign(email, password);
      setAuthFlow(resp);
      var cur = mapCurrentUser();
      setUser(cur);
      return resp;
   };

   const logout = () => {
      currentUser()?.signOut();
      setUser(undefined);
      setAuthFlow(undefined);
   };

   const changePassword = async (
      password: string,
      oldPassword: string
   ): Promise<AuthenticationResponse> => {
      var user = currentUser();
      if (authFlow === undefined && !!user) {
         var respChangePassword = await cognitoChangePassword(user, oldPassword, password);
         setAuthFlow(respChangePassword);
         var cur2 = mapCurrentUser();
         setUser(cur2);
         return respChangePassword;
      }

      return {
         type: "failure",
         failureDetails: { message: "Não foi possível alterar a senha. Sessão não encontrada." }
      };
   };

   const formValue = formValues as FormValueUserProps;
   const email = formValue?.email;
   const password = formValue?.password;

   const resendEmailConfirmationCode = async (setToast: any) => {
      var resp = await resendEmailConfirmCode(email);

      if (!!resp) {
         const title = "Código de verificação enviado";
         const description =
            "Um e-mail com o código de verificação foi enviado para o seu endereço de e-mail. Por favor, verifique sua caixa de entrada e spam se necessário.";
         ShowToast.showSuccessToast(title, description, setToast);
      } else {
         const title = "Ops, ocorreu um erro!";
         const description =
            "Não foi possível reenviar o código de confirmação para o o e-mail informado. Por favor, tente novamente mais tarde.";
         ShowToast.showErrorToast(title, description, setToast);

         setAuthFlow({
            type: "failure",
            failureDetails: {
               message: `Não foi possível enviar o código de confirmação do e-mail. Sessão não encontrada.`
            }
         });
      }
   };

   console.log({ user, token })

   const confirmEmailCode = async (emailCode: string, setToast: any) => {
      startRequest();
      var resp = await verifyEmail(email, emailCode, password);
      if (resp.type === "success") {
         setAuthFlow({
            type: "success",
            user: resp.user!
         });
         endRequest(true);
         navigate("/bank-account-request");
      } else {
         const title = "Ops, ocorreu um erro!";
         const description =
            "Não foi possível enviar o código de confirmação do e-mail. O código pode ter expirado ou ser inválido.";
         ShowToast.showErrorToast(title, description, setToast);

         setAuthFlow({
            type: "failure",
            failureDetails: {
               message: `Não foi possível enviar o código de confirmação do e-mail. Sessão não encontrada.`
            }
         });
      }
   };

   const clearSubmitError = () => setSubmitError(undefined);

   const sendCodeForPasswordRecover = async (email: string): Promise<AuthenticationResponse> => {
      var resp = await forgotPassword(email);
      setAuthFlow(resp);
      return resp;
   };

   const confirmPasswordRecover = async (
      email: string,
      code: string,
      password: string
   ): Promise<AuthenticationResponse> => {
      var resp = await confirmForgotPassword(email, code, password);
      if (resp.type === "success") {
         resp = await signIn(email, password);
      }
      setAuthFlow(resp);
      setUser(mapCurrentUser());
      return resp;
   };

   const navigateToFlow = (navigate: NavigateFunction): void => {
      switch (authFlow?.type) {
         case "success":
            isSignedIn ? navigate("/retomar-solicitacao") : navigate("/bank-account-request");
            break;
         case "failure":
            break;
      }
   };

   return (
      <UserContext.Provider
         value={{
            user,
            token,
            authFlow,
            submitError,
            clearSubmitError,
            logout,
            signIn,
            changePassword,
            isAuthenticated,
            navigateToFlow,
            confirmEmailCode,
            resendEmailConfirmationCode,
            sendCodeForPasswordRecover,
            confirmPasswordRecover,
            setIsSignedIn
         }}
      >
         {children}
      </UserContext.Provider>
   );
};

export function useIdentity(): UserContextProps {
   const context = useContext(UserContext);
   const {
      user,
      token,
      authFlow,
      submitError,
      logout,
      signIn,
      changePassword,
      clearSubmitError,
      isAuthenticated,
      navigateToFlow,
      confirmEmailCode,
      resendEmailConfirmationCode,
      sendCodeForPasswordRecover,
      confirmPasswordRecover,
      setIsSignedIn
   } = context;

   return {
      user,
      token,
      authFlow,
      submitError,
      logout,
      signIn,
      changePassword,
      isAuthenticated,
      clearSubmitError,
      navigateToFlow,
      confirmEmailCode,
      resendEmailConfirmationCode,
      sendCodeForPasswordRecover,
      confirmPasswordRecover,
      setIsSignedIn
   };
}
