import { LogoutIcon } from "@uy3/web-components";
import "./Logout.scss";
import { iconSx } from "context/apiRequestContext";
import { IconButton, Stack } from "@mui/material";
import { useIdentity } from "context/identityUser";
import { useNavigate } from "react-router";

const Logout = () => {
   const { logout } = useIdentity();
   const navigate = useNavigate();

   const handleClick = () => {
      logout();
      navigate("/");
   };

   return (
      <Stack className="logout-container" direction="row" alignItems="center">
         <IconButton onClick={handleClick}>
            <LogoutIcon sx={iconSx} htmlColor="#FFFFFF" />
            <div className="logout-button">Sair</div>
         </IconButton>
      </Stack>
   );
};

export default Logout;
