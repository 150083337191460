import { FunctionComponent } from "react";
import { HeaderProps } from "./Header.interface";
import "./Header.scss";
import { activeConfig } from "services/config";

const configs = activeConfig();
const Header: FunctionComponent<HeaderProps> = () => {
   return (
      <div className="styledHeader">
         <div className="logoHeader">
            <img src={configs.MAIN_LOGO} alt="header-logo" />
         </div>
      </div>
   );
};

export default Header;
