import { Pagination, PaginationProps, ThemeProvider, createTheme } from "@mui/material";
import { activeTheme } from "services/theme";

interface PaginationComponentProps extends PaginationProps {}

const theme = activeTheme();
const themeMUI = createTheme({
   palette: {
      primary: theme.palette.primary
   }
});

const PaginationComponent = ({ ...props }: PaginationComponentProps) => {
   return (
      <ThemeProvider theme={themeMUI}>
         <Pagination {...props} />
      </ThemeProvider>
   );
};

export default PaginationComponent;
