import React, { createContext, useContext, useEffect, useState } from "react";
import { IConfigs, activeConfig, loadConfig } from "services/config";
interface AppConfigProviderProps {
   children: JSX.Element;
}

interface AppConfigContextProps {
   appConfig: IConfigs;
   setAppConfig: (config: IConfigs) => void;
}

const AppConfigContext = createContext<AppConfigContextProps>({
   appConfig: {} as IConfigs,
   setAppConfig: () => {}
});

export const AppConfigProvider = ({ children }: AppConfigProviderProps) => {
   var currentConfig = activeConfig();
   const [appConfig, setAppConfig] = useState<IConfigs>(currentConfig);

   useEffect(() => {
      const domain = window.document.location.hostname;
      loadConfig(domain).then((config: IConfigs) => {
         if (JSON.stringify(config) !== JSON.stringify(appConfig)) {
            window.location.reload();
         }
         setAppConfig(config);
      });
   }, []);

   return (
      <AppConfigContext.Provider
         value={{
            appConfig,
            setAppConfig
         }}
      >
         {children}
      </AppConfigContext.Provider>
   );
};

export function useAppConfig(): AppConfigContextProps {
   const context = useContext(AppConfigContext);
   const { appConfig, setAppConfig } = context;

   return {
      appConfig,
      setAppConfig
   };
}
