import { Paper } from "@mui/material";
import { Button } from "@uy3/web-components";
import { useFormContext } from "context/form/formContext";
import { useUploadMutation } from "context/uploadContext";
import React from "react";
import { UploadNew } from "services";

interface UploadDropProps {
   accept: string;
   isMultiple?: boolean;
   setFiles?: React.Dispatch<React.SetStateAction<any[]>>;
}

const UploadDropzone: React.FC<UploadDropProps> = ({ accept, isMultiple = false, setFiles }) => {
   const { setValue, watch } = useFormContext();
   const documentType = watch("documentType");

   const { mutate } = useUploadMutation((data: UploadNew, formValue: File) => {
      const fileName = data.filename;
      const fileDisplayName = formValue.name;
      const tempGetUrl = data.getUrl;

      const fileType =
         accept === ".pdf"
            ? "SocialContract"
            : documentType === "CNH"
            ? "DriversLicense"
            : "IdentityCard";
      const watchUploads = accept === ".pdf" ? "socialContract" : "uploads";
      const currentUploads = watch(watchUploads) ?? [];

      const newUpload = {
         displayName: fileDisplayName,
         fileName: fileName,
         tempGetUrl: tempGetUrl,
         fileType,
         id: null
      };

      const updatedUploads = [...currentUploads, newUpload];
      setValue(watchUploads, updatedUploads);

      setFiles &&
         setFiles((prev) => [
            ...prev,
            {
               id: null,
               fileTypeDisplay: null,
               displayName: fileDisplayName,
               fileName,
               tempUrl: tempGetUrl
            }
         ]);
   });

   const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => event.preventDefault();

   const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const files = event.dataTransfer?.files;
      if (files && files.length > 0) {
         if (isMultiple) {
            const filesArray = Array.from(files);
            filesArray.forEach((file) => mutate(file));
            return;
         }

         const file = files[0];
         mutate(file);
      }
   };

   return (
      <>
         <Paper sx={baseStyle} onDragOver={handleDragOver} onDrop={handleDrop}>
            <Button
               variant="text"
               fullWidth
               component="label"
               size={"medium"}
               name="btn-dropzone-input"
            >
               Anexar arquivo
               <input
                  name="dropzone-input"
                  hidden
                  multiple={isMultiple}
                  type="file"
                  accept={accept}
                  onChange={(e) => {
                     const file = e.target.files?.item(0);
                     if (file) mutate(file);
                  }}
               />
            </Button>
         </Paper>
      </>
   );
};

export default UploadDropzone;

export const baseStyle = {
   cursor: "pointer",
   display: "block",
   border: "1px solid #D04D27",
   "&:hover": { border: "1px solid #ccc" },
   boxShadow: "0px 8px 32px rgba(186, 191, 208, 0.32)",
   borderRadius: "8px",
   color: "#bdbdbd",
   outline: "none",
   transition: "border .24s ease-in-out"
};
