import React from "react";
import "./Card.scss";
import { CardProps } from "./Card.interface";
import { Typography } from "@uy3/web-components";
import { Radio } from "components/Forms";

export const Card: React.FC<CardProps> = ({
   title,
   description,
   options,
   onChange,
   value,
   icon,
   disabled
}) => {
   return (
      <label className={`card-radio ${disabled ? "disabled" : ""}`}>
         <Radio size="medium" value={value} options={options} onChange={onChange} />
         <div className="card-radio__icon">{icon}</div>
         <div className="card-radio__content">
            <Typography variant="sm" className="title">
               {title}
            </Typography>
            <Typography variant="body1" className="subtitle">
               {description}
            </Typography>
         </div>
      </label>
   );
};
