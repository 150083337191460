import { FunctionComponent } from "react";
import "./HelpButton.scss";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { activeConfig } from "services/config";

const appConfig = activeConfig();
export const HelpButton: FunctionComponent = () => {
   const {
      SUPPORT: { whatsapp }
   } = appConfig;
   const { contact } = whatsapp;
   const link = `https://api.whatsapp.com/send?phone=${contact}`;

   return (
      <Link className="helpContainer" to={link} target="_blank">
         <p>Precisa de ajuda?</p>
         <FaWhatsapp />
      </Link>
   );
};

export default HelpButton;
