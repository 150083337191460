type FormatMultiplyType = "percentage" | "currency";

export default class FormatCurrency {
   static formatDivide(num: number | undefined): number | undefined {
      if (typeof num !== "number" || isNaN(num) || !isFinite(num)) {
         return undefined;
      }

      return num / 100;
   }

   static formatMultiply(num: number | undefined, type: FormatMultiplyType = "percentage"): number {
      if (num === undefined || isNaN(num) || !num) return 0;
      let multipliedValue = num * 100;
      const value = type === "currency" ? Math.round(multipliedValue) : multipliedValue;
      return value;
   }

   static formatCurrencyInCents(num: number | undefined) {
      if (num === undefined || isNaN(num) || !num) return "R$ 0,00";
      const dividedValue = FormatCurrency.formatDivide(num);

      return dividedValue!?.toLocaleString("pt-BR", {
         style: "currency",
         currency: "BRL",
         minimumFractionDigits: 2,
         maximumFractionDigits: 2
      });
   }

   static maskCurrencyInCents(num: number | undefined) {
      if (num === undefined || isNaN(num) || !num) return "R$ 0,00";
      return num!?.toLocaleString("pt-BR", {
         style: "currency",
         currency: "BRL",
         minimumFractionDigits: 2,
         maximumFractionDigits: 2
      });
   }

   static formatPercentage(value: number) {
      if (!value) return 0;

      const percentage = `${(value * 100).toFixed(4)}%`;
      return percentage.toString().replace(".", ",");
   }
}
