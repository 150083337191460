import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { ImYoutube2 } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { FunctionComponent, ReactNode } from "react";
import { SocialItemProps, SocialListProps } from "./SocialItem.interface";
import "./SocialItem.scss";
import { socialList } from "./socialList";

export const SocialItem: FunctionComponent<SocialListProps> = ({
   htmlColor = "#FFFFFF",
   hidden = false
}) => {
   const icons: { [key: string]: ReactNode } = {
      "facebook-f": <FaFacebookF color={htmlColor} />,
      "instagram-in": <FaInstagram color={htmlColor} />,
      twitter: <FaXTwitter color={htmlColor} />,
      youtube: <ImYoutube2 size={45} color={htmlColor} />,
      "linkedin-in": <FaLinkedinIn color={htmlColor} />,
      envelop: <MdEmail color={htmlColor} />
   };

   return (
      <div className="socialContainer">
         {!hidden &&
            socialList?.map((element: SocialItemProps, index: number) => {
               const { icon, title, url } = element;
               return (
                  <div key={`SocialItem_${index}`}>
                     <div className="socialItem">
                        <Link to={url} title={title} target="_blank">
                           <>{icons[icon]}</>
                        </Link>
                     </div>
                  </div>
               );
            })}
      </div>
   );
};
