import { activeConfig } from "services/config";
import {
   CognitoUserPool,
   CognitoUser,
   AuthenticationDetails,
   CognitoUserSession,
   CognitoUserAttribute
} from "amazon-cognito-identity-js";
import { isEmpty } from "lodash";
import { AuthenticationResponse, FailureResponse, SignUpProps, SuccessResponse } from "./identity.interface";
import { onlyNumbers } from "utils/appUtils";
import { config as AWSConfig, CognitoIdentityCredentials } from "aws-sdk";

var appConfig = activeConfig();
const ClientId = appConfig.USER_CLIENT_ID;
const UserPoolId = appConfig.USER_POOL_ID;
const AwsRegion = appConfig.AWS_REGION;
const IdentityPoolId = appConfig.USER_IDENTITY_ID;

export function currentUser(): CognitoUser | null {
   const cognitoUserPool = new CognitoUserPool({ ClientId, UserPoolId });
   return cognitoUserPool.getCurrentUser();
}

export function signOutAndRedirectLogin() {
   const user: CognitoUser | null = currentUser();
   if (user) {
      user.signOut();
   }
   window.location.href = `/login`;
}

export function refreshToken() {
   return new Promise((resolve, reject) => {
      const user: CognitoUser | null = currentUser();

      if (user != null) {
         user.setAuthenticationFlowType("REFRESH_TOKEN");
         user.getSession((err: any, session: any) => {
            if (err) {
               signOutAndRedirectLogin();
               reject(err);
            } else {
               localStorage.addItem("user", JSON.stringify(session?.getIdToken()));
               resolve(session);
            }
         });
      } else {
         signOutAndRedirectLogin();
         reject(Error("Cognito user not found"));
      }
   });
}

export async function authenticateUser(username: string, password: string): Promise<string> {
   const authenticationData = {
      Username: username,
      Password: password
   };

   const authenticationDetails = new AuthenticationDetails(authenticationData);

   const userData = {
      Username: username,
      Pool: new CognitoUserPool({ ClientId, UserPoolId })
   };

   const cognitoUser = new CognitoUser(userData);

   return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
         onSuccess: (result) => {
            const token: string = result.getIdToken().getJwtToken();
            updateCredentials(token);
            resolve(token);
         },
         onFailure: (error) => {
            if (error.message && error.message.includes("UserMigration failed with error")) {
               reject({
                  errorMessage: error.message.replace("UserMigration failed with error", "")
               });
            } else {
               reject(error);
            }
         },
         newPasswordRequired: () => {
            reject({ newPasswordRequired: true });
         }
      });
   });
}

export async function updateCredentials(token: string): Promise<void> {
   return new Promise((resolve, reject) => {
      AWSConfig.region = AwsRegion;
      AWSConfig.credentials = new CognitoIdentityCredentials({
         IdentityPoolId,
         Logins: {
            [`cognito-idp.${AwsRegion}.amazonaws.com/${UserPoolId}`]: token
         }
      });
      AWSConfig.getCredentials((err) => {
         if (err) {
            reject(err);
         } else {
            resolve();
         }
      });
   });
}

export function resendEmailConfirmCode(email: string) {
   const cognitoUserPool = new CognitoUserPool({ ClientId, UserPoolId });

   const userData = {
      Username: email,
      Pool: cognitoUserPool
   };

   const cognito: CognitoUser = new CognitoUser(userData);

   return new Promise((resolve, reject) => {
      cognito.resendConfirmationCode((err, result) => {
         if (err) {
            reject(err);
         } else {
            resolve(result);
         }
      });
   });
}

export async function verifyEmail(email: string, confirmationCode: string, password: string): Promise<AuthenticationResponse> {
   const cognitoUserPool = new CognitoUserPool({ ClientId, UserPoolId });
 
   const userData = {
     Username: email,
     Pool: cognitoUserPool
   };
 
   const cognito: CognitoUser = new CognitoUser(userData);
 
   return new Promise((resolve, reject) => {
      cognito.confirmRegistration(confirmationCode, true, async (error, _) => {
       if (error) {
         reject({
           type: "failure",
           user: cognito,
           failureDetails: {
             message: error.message,
             code: error.code,
             error: error
           }
         } as FailureResponse);
       } else {
         const finalEmail = email.toLocaleLowerCase().trim();
         const authenticationDetails = new AuthenticationDetails({
           Username: finalEmail,
           Password: password
         });
 
         cognito.authenticateUser(authenticationDetails, {
           onSuccess: (_: CognitoUserSession) => {
             getUserAttributes(cognito).then(_ => {
               resolve({
                 type: "success",
                 user: cognito
               } as SuccessResponse);
             }).catch(attrError => {
               reject({
                 type: "failure",
                 user: cognito,
                 failureDetails: {
                   message: attrError.message,
                   error: attrError
                 }
               } as FailureResponse);
             });
           },
           onFailure: (authError: any) => {
             reject({
               type: "failure",
               user: cognito,
               failureDetails: {
                 message: authError.message,
                 code: authError.code,
                 error: authError
               }
             } as FailureResponse);
           }
         });
       }
     });
   });
 }

 
interface UserAttributes {
   [key: string]: string;
 }
 
function getUserAttributes(user: CognitoUser): Promise<any> {
   return new Promise((resolve, reject) => {
      user.getSession((err: Error | null, session: CognitoUserSession | null) => {
         if (err) {
            reject(err);
            return;
         }

         if (!session || !session.isValid()) {
            reject(new Error("Session is invalid"));
            return;
         }

         user.getUserAttributes((err, attributes) => {
            if (err) {
               reject(err);
               return;
            }

            const userAttributes = attributes?.reduce((acc: UserAttributes, attribute: CognitoUserAttribute) => {
               acc[attribute.getName()] = attribute.getValue();
               return acc;
             }, {});

            resolve(userAttributes);
         });
      });
   });
}

export async function signUp(data: SignUpProps): Promise<AuthenticationResponse> {
   const { email, address, name, password, phoneNumber, registrationNumber } = data;
   const phone_number = onlyNumbers(phoneNumber);
   const registration_number = onlyNumbers(registrationNumber);

   const cognitoUserPool = new CognitoUserPool({ ClientId, UserPoolId });

   const attributeList: Array<CognitoUserAttribute> = [];

   const attributes = (key: string, value: string) => {
      return {
         Name: key,
         Value: value
      };
   };

   const attributeEmail: CognitoUserAttribute = new CognitoUserAttribute(
      attributes("email", email)
   );
   const attributePhone: CognitoUserAttribute = new CognitoUserAttribute(
      attributes("phone_number", "+55" + phone_number)
   );
   const attributeName: CognitoUserAttribute = new CognitoUserAttribute(attributes("name", name));
   const attributeAddress: CognitoUserAttribute = new CognitoUserAttribute(
      attributes("address", address)
   );

   const attributeRegistrationNumber: CognitoUserAttribute = new CognitoUserAttribute(
      attributes("custom:registrationNumber", registration_number)
   );

   attributeList.push(attributeEmail);
   attributeList.push(attributePhone);
   attributeList.push(attributeName);
   attributeList.push(attributeAddress);
   attributeList.push(attributeRegistrationNumber);

   const finalEmail = email.toLocaleLowerCase().trim();
   return new Promise((resolve, reject) => {
      cognitoUserPool.signUp(finalEmail, password, attributeList, [], async (err: any, result) => {
         if (err) {
            reject(codeToMessage(err.code));
         } else {
            if (result && result.user) {
               resolve({ type: "success", user: result.user });
            } else {
               reject(new Error("Usuário não retornado após o registro."));
            }
         }
      });
   });
}

export async function signIn(email: string, password: string): Promise<AuthenticationResponse> {
   var appConfig = activeConfig();
   const cognitoUserPool = new CognitoUserPool({
      ClientId: appConfig.USER_CLIENT_ID,
      UserPoolId: appConfig.USER_POOL_ID
   });
   return new Promise((resolve) => {
      const finalEmail = email.toLocaleLowerCase().trim();
      const authenticationDetails = new AuthenticationDetails({
         Username: finalEmail,
         Password: password
      });
      const userData = {
         Username: finalEmail,
         Pool: cognitoUserPool
      };
      let cognitoUser = new CognitoUser(userData);

      const callbacks = cognitoCallbacks(cognitoUser, resolve);
      cognitoUser.authenticateUser(authenticationDetails, callbacks);
   });
}

export async function changePassword(
   cognitoUser: CognitoUser,
   oldPassword: string,
   newPassword: string
): Promise<AuthenticationResponse> {
   return new Promise((resolve) => {
      const { onSuccess, onFailure } = cognitoCallbacks(cognitoUser, resolve);
      cognitoUser.setAuthenticationFlowType("REFRESH_TOKEN");
      cognitoUser.getSession((err: any, session: any) => {
         if (err) {
            resolve(failureFromError(err, cognitoUser));
         } else {
            cognitoUser.changePassword(oldPassword, newPassword, (changePasswordError) => {
               if (changePasswordError) {
                  onFailure(changePassword);
               } else {
                  onSuccess(session);
               }
            });
         }
      });
   });
}

export async function forgotPassword(email: string): Promise<AuthenticationResponse> {
   const cognitoUserPool = new CognitoUserPool({ ClientId, UserPoolId });
   return new Promise((resolve) => {
      const userData = {
         Username: email.toLocaleLowerCase().trim(),
         Pool: cognitoUserPool
      };
      const cognitoUser = new CognitoUser(userData);
      const { onSuccessPreVerification, onFailure } = cognitoCallbacks(cognitoUser, resolve);
      cognitoUser.forgotPassword({
         onSuccess: onSuccessPreVerification,
         onFailure: onFailure
      });
   });
}

export function confirmForgotPassword(
   email: string,
   verificationCode: string,
   password: string
): Promise<AuthenticationResponse> {
   const cognitoUserPool = new CognitoUserPool({ ClientId, UserPoolId });
   return new Promise((resolve) => {
      var userData = {
         Username: email.toLocaleLowerCase().trim(),
         Pool: cognitoUserPool
      };
      const cognitoUser: CognitoUser = new CognitoUser(userData);

      const { onSuccessVerification, onFailure } = cognitoCallbacks(cognitoUser, resolve);
      cognitoUser.confirmPassword(verificationCode, password, {
         onSuccess: onSuccessVerification,
         onFailure: onFailure
      });
   });
}

const cognitoCallbacks = (cognitoUser: CognitoUser, resolve: any) => {
   return {
      onSuccessPreVerification: (success: string) => {
         resolve({ type: "verificationEmail", user: cognitoUser, success });
      },
      onSuccessVerification: (success: string) => {
         resolve({ type: "success", user: cognitoUser, success });
      },
      onSuccess: (session: CognitoUserSession) => {
         console.log("UserService - authenticate User Success", { session });

         resolve({ type: "success", user: cognitoUser });
      },
      onFailure: (error: any) => {
         const message = String(error?.message);
         if (!isEmpty(message) && !!error?.message && error?.message === "User is disabled.") {
            return resolve({
               type: "failure",
               user: cognitoUser,
               failureDetails: {
                  message: "Usuário desativado. Entre em contato com o administrador do sistema.",
                  code: error.code,
                  error
               }
            });
         }
         console.log("error on cognito callback", error);
         resolve(failureFromError(error, cognitoUser));
      },
      newPasswordRequired: () => {
         resolve({ type: "newPassword", user: cognitoUser });
      }
   };
};

function failureFromError(error: any, user: CognitoUser): FailureResponse {
   return error.code
      ? {
           type: "failure",
           user: user,
           failureDetails: {
              message: codeToMessage(error.code),
              code: error.code,
              error
           }
        }
      : {
           type: "failure",
           user: user,
           failureDetails: {
              message: "Erro inesperado. Tente novamente.",
              error
           }
        };
}

function codeToMessage(code: string) {
   switch (code) {
      case "LimitExceededException":
         return "Limite de tentativas excedido. Tente novamente mais tarde.";
      case "UserNotConfirmedException":
         return "Este usuário não esta confirmado ainda. Confirme antes de prosseguir.";
      case "PasswordResetRequiredException":
         return "Você precisa alterar sua senha. Clique em 'Esqueci minha senha'";
      case "NotAuthorizedException":
         return "Usuário e senha incorretos.";
      case "ResourceNotFoundException":
         return "Usuário e/ou senha incorretos.";
      case "UsernameExistsException":
         return "Este e-mail/nome de usuário já esta sendo utilizado.";
      case "UserNotFoundException":
         return "Este usuário não existe.";
      case "CodeMismatchException":
         return "Código inválido. Tente novamente.";
      case "EnableSoftwareTokenMFAException":
         return "Não foi possível validar o código. Tente novamente.";
      case "ExpiredCodeException":
         return "O código informado expirou. Tente novamente com um novo código";
      default:
         return "Ocorreu um erro ao realizar a requisição. Tente novamente.";
   }
}
