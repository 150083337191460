import { SelectOption } from "components";

export default class AppUtils {
   static sendSubmitEvent = (event: any) => {
      const submitEvent = new Event("submit", { bubbles: true });
      event.target.dispatchEvent(submitEvent);
   };

   static resolve(path: string | string[], obj: any) {
      const separator = ".";
      let properties = Array.isArray(path) ? path : path.split(separator);
      return properties.reduce(
         (previousValue: any, currentValue: string) => previousValue?.[currentValue],
         obj
      );
   }

   static sortToSelectOptionList(options: SelectOption[] | undefined) {
      if (!options) return [];
      const typeOfDocumentsOptions = options
         .map((option) => {
            return { label: option?.label, value: option?.value };
         })
         .sort((a, b) => {
            const label = a.label.toUpperCase();
            const value = b.label.toUpperCase();
            if (label < value) {
               return -1;
            }
            if (label > value) {
               return 1;
            }
            return 0;
         });
      return typeOfDocumentsOptions;
   }

   static removeDuplicateData(dataArray: any[], key: string | string[]): any[] {
      const uniqueData: any[] = [];
      const seenIds = new Set<string | number>();
   
      for (const data of dataArray) {
         const keyValue = Array.isArray(key) ? key.map((k) => data[k]).join(",") : data[key];
         if (!seenIds.has(keyValue)) {
            uniqueData.push(data);
            seenIds.add(keyValue);
         }
      }
   
      return uniqueData;
   }
}
