import { Calendar } from "react-date-range";
import pt from "date-fns/locale/pt";
import "react-date-range/dist/styles.css"; // Importa o estilo padrão
import "react-date-range/dist/theme/default.css"; // Importa o tema padrão
import { activeTheme } from "services/theme";
import { MainBox } from "./DatePickerFormField.styled";
import { useRef, useState } from "react";
import { CalendarIcon, SearchIcon, TextField, Typography } from "@uy3/web-components";
import { IconButton, InputAdornment, Stack, Popover } from "@mui/material";
import moment from "moment";
import { MaskedInput } from "components/Forms/MaskedInput";
import { parse, isValid } from "date-fns";
import { AppUtils } from "utils/appUtils";
import { dateMask } from "utils/constants/masks/dateMask";
import { useFormContext } from "context/form/formContext";
import { DatePickerFormFieldProps } from "./DatePicker.interface";

const theme = activeTheme();
export function DatePickerFormField({
   label,
   name,
   fullWidth,
   required = false,
   disabled = false,
   disablePast,
   disableFuture,
   showEndAdornment = false,
   componentFilter,
   shrink = false
}: DatePickerFormFieldProps) {
   const [keyboard, setKeyboard] = useState<string>();
   const { validationErrors, watch, setValue, readOnly } = useFormContext();
   const [anchorEl, setAnchorEl] = useState<any>(null);
   const componentRef = useRef(null);
   const error = validationErrors && AppUtils.resolve(name, validationErrors)?.message;
   const onClose = () => setAnchorEl(false);
   const currentValue = watch(name);

   const hasValue = () => {
      if (currentValue !== null) {
         return keyboard !== undefined || currentValue?.toString()?.length > 0 ? true : false;
      }
      return false;
   };

   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      !readOnly && setAnchorEl(event.currentTarget);
   };

   const onChange = (event: Date) => {
      if (setValue) {
         setValue(name, event.toISOString());
         onClose();
         setKeyboard(moment(event).format("DD/MM/YYYY"));
      }
   };

   function hasEmptyString(...strings: string[]): boolean {
      return strings.some((str) => {
         const cleanedStr = str.replace(/\D/g, ""); // Remove non-numeric characters
         return cleanedStr === "" || cleanedStr.length !== str.length; // Check if it is empty or has invalid size
      });
   }

   const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = ev.target.value;
      const date = value?.split("/");
      const day = date[0];
      const month = date[1];
      const year = date[2];
      const valueLength = value.replace(/\D/g, "").length === 8;

      if (value === "" || hasEmptyString(day, month, year)) setValue(name, null);
      if (valueLength) {
         const dateString = `${year}-${month}-${day}`;
         const parsedDate = parse(dateString, "yyyy-MM-dd", new Date());

         if (isValid(parsedDate)) {
            const value = parsedDate.toISOString();
            setValue(name, value);
         }
      }
      setKeyboard(value);
   };

   const isRequired = !!required;
   const labelWithRequired = isRequired ? `${label} *` : label;
   const skipShrink = shrink || hasValue() || Boolean(keyboard && keyboard?.length > 0);

   return (
      <div ref={componentRef}>
         <Stack direction="column" alignItems="center" position="relative">
            <TextField
               variant="outlined"
               fullWidth={fullWidth}
               disabled={readOnly || disabled || !!anchorEl}
               label={labelWithRequired}
               onChange={handleChange}
               value={
                  currentValue !== undefined
                     ? keyboard ?? moment(currentValue).format("DD/MM/YYYY")
                     : undefined
               }
               error={error}
               InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: { mask: dateMask },
                  endAdornment: (
                     <>
                        <IconButton
                           sx={{
                              padding: "15px 0"
                           }}
                           onClick={handleClick}
                        >
                           <InputAdornment position="end" sx={{ marginRight: 1 }}>
                              <CalendarIcon
                                 htmlColor={
                                    disabled || readOnly
                                       ? theme.palette.grey[500]
                                       : theme.palette.primary.main
                                 }
                              />
                           </InputAdornment>
                        </IconButton>
                        {showEndAdornment && (
                           <InputAdornment position="end" sx={{ marginX: -0.9 }}>
                              <IconButton type="submit">
                                 <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                              </IconButton>
                           </InputAdornment>
                        )}
                        {componentFilter && <>{componentFilter}</>}
                     </>
                  )
               }}
               InputLabelProps={{
                  shrink: skipShrink
               }}
            />
         </Stack>
         {!!error && (
            <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
               <>{error}</>
            </Typography>
         )}

         <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "right"
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "right"
            }}
         >
            <MainBox>
               <Calendar
                  date={hasValue() ? new Date(currentValue) : new Date()}
                  minDate={disablePast ? new Date() : undefined}
                  maxDate={disableFuture ? new Date() : undefined}
                  onChange={onChange}
                  preventSnapRefocus={false}
                  locale={pt}
                  color={theme.palette.info.main}
                  rangeColors={[theme.palette.primary.main, "blue", "green"]}
               />
            </MainBox>
         </Popover>
      </div>
   );
}
