import { IconButton, InputAdornment } from "@mui/material";
import { BankingIcon, SearchIcon, TextField, Typography } from "@uy3/web-components";
import { activeTheme } from "services/theme";
import { NumericFormat, InputAttributes, NumericFormatProps } from "react-number-format";
import { isEmpty } from "lodash";
import { ICurrencyFormFieldProps, ICustomProps } from "./CurrencyFormField.interface";
import { useFormContext } from "context/form/formContext";
import { FormatCurrency, AppUtils } from "utils/appUtils";
import React from "react";

const theme = activeTheme();
export const NumberFormatCustom = React.forwardRef<
   NumericFormatProps<InputAttributes>,
   ICustomProps
>(function NumberFormatCustom(props, ref) {
   const { onChange, customInput, value, name, ...other } = props;

   return (
      <NumericFormat
         {...other}
         getInputRef={ref}
         onValueChange={(values: any) => {
            onChange({
               target: {
                  name: name,
                  value: values.value
               }
            });
         }}
         thousandSeparator="."
         decimalSeparator=","
         valueIsNumericString
         decimalScale={2}
         fixedDecimalScale={true}
         customInput={customInput}
         value={value}
      />
   );
});

const CurrencyFormField: React.FC<ICurrencyFormFieldProps> = ({
   required,
   label,
   showEndAdornment,
   ...props
}) => {
   const { validationErrors, watch, setValue, readOnly } = useFormContext();
   const name = props.name;

   let value = undefined;
   if (watch) {
      value = watch(name);
      if (value) {
         value = FormatCurrency.formatDivide(value);
      }
   }

   let error = validationErrors && AppUtils.resolve(name, validationErrors)?.message;

   const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = target;
      let newValue: number;

      if (!isEmpty(value)) {
         const parsedValue = parseFloat(value);
         newValue = FormatCurrency.formatMultiply(parsedValue, "currency");
         setValue(name, newValue, { shouldDirty: true });
      } else {
         setValue(name, null, { shouldDirty: true });
      }
   };

   const isRequired = !!required;
   const labelWithRequired = isRequired ? `${label} *` : label;
   const shrink = watch(name) ? true : false;

   return (
      <>
         <TextField
            id={name}
            label={labelWithRequired}
            error={!!error}
            disabled={readOnly}
            value={value === null ? "" : value}
            onChange={handleChange}
            InputProps={{
               startAdornment: (
                  <InputAdornment position="start">
                     <BankingIcon
                        htmlColor={`${readOnly ? "#00000061" : "#666666"}`}
                        sx={{ height: 18, width: 18 }}
                     />
                  </InputAdornment>
               ),
               endAdornment: (
                  <>
                     {showEndAdornment && (
                        <InputAdornment position="end">
                           <IconButton type="submit">
                              <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                           </IconButton>
                        </InputAdornment>
                     )}
                  </>
               ),
               inputComponent: NumberFormatCustom as any
            }}
            InputLabelProps={{
               shrink
            }}
            {...props}
         />

         {!!error && (
            <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
               <>{error}</>
            </Typography>
         )}
      </>
   );
};

export default CurrencyFormField;
