import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { AppConfigProvider } from "context/appConfig";
import { RefreshProgress } from "./components";

const App = lazy(() => import("./app/App"));

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
   <React.StrictMode>
      <BrowserRouter>
         <Suspense fallback={<RefreshProgress refreshing={true} />}>
            <AppConfigProvider>
               <App />
            </AppConfigProvider>
         </Suspense>
      </BrowserRouter>
   </React.StrictMode>,
   document.getElementById("root")
);
