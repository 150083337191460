import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { activeTheme } from "services/theme";

interface CustomGridProps {
   borderColor?: string;
}

const theme = activeTheme();

export const CustomGrid = styled(Grid)<CustomGridProps>`
   border-radius: 8px;
   padding: 16px;
   justify-content: space-between;
   align-items: center;
   border: 1px solid;
   border-color: ${(props) => props.borderColor};

   &:hover {
      border-color: ${theme.palette.primary.main};
   }
`;
