import { Theme } from "@mui/material";
import { UY3Icon, light } from "@uy3/web-components";
import { activeConfig } from "services/config";

export function activeTheme(): Theme {
   let config = activeConfig();

   let {
      spacing,
      shadows,
      components,
      typography,
      breakpoints,
      direction,
      shape,
      transitions,
      zIndex,
      mixins,
      unstable_sx,
      unstable_sxConfig,
      unstable_strictMode
   } = light;
   let theme = {
      spacing,
      mixins,
      unstable_strictMode,
      unstable_sx,
      unstable_sxConfig,
      shape,
      shadows,
      typography,
      breakpoints,
      transitions,
      zIndex,
      direction,
      components: {
         Icon:
            config && config.MAIN_LOGO ? (
               <img src={config.MAIN_LOGO} width="100%" alt="logo" />
            ) : (
               <UY3Icon />
            ),
         ...components
      },
      palette: { ...light.palette }
   };

   return theme as unknown as Theme;
}
