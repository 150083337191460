import { UploadNew, getSignedUrl, uploadFile } from "services/upload";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { useIdentity } from "context/identityUser";
import { ApiResponseError } from "context/apiRequestContext/apiRequest.interface";
import { useApiRequest } from "context/apiRequestContext";

export type FileFull = {
   lastModifiedDate: Date;
   size: number;
   type: string;
} & File;

export function useUploadMutation(
   onSuccess?: (data: UploadNew, formValue: File) => void,
   onError?: (error: any) => void
) {
   const { token } = useIdentity();
   const { startRequest, endRequest, setSubmitError } = useApiRequest();

   return useMutation({
      onSuccess: (data: UploadNew | ApiResponseError, formValue: File) => {
         onSuccess && onSuccess(data as UploadNew, formValue);
      },
      onError(error) {
         let message = "Erro desconhecido. Por favor, entre em contato com o suporte técnico.";
         let apiError: ApiResponseError = { type: "error", message, code: "UNKNOWN", errors: [] };
         if (isAxiosError(error)) {
            const axErr = error as AxiosError;
            apiError = { type: "error", code: axErr.code!, errors: [] };
            const { response } = axErr;
            if (response) {
               const { data } = response;
               let respData = data as ApiResponseError;
               if (data) {
                  apiError = respData;
               }
            }
         }
         endRequest(false);
         setSubmitError(apiError);
         onError && onError(apiError);
      },
      mutationFn: async (file: File): Promise<UploadNew | ApiResponseError | any> => {
         startRequest();
         let responseUrlSigned;
         if (responseUrlSigned === undefined) {
            const { data, status, statusText } = await getSignedUrl(file.name, token!);
            if (isAxiosError(data)) {
               setSubmitError({
                  type: "error",
                  code: status + "" + statusText,
                  message: data.message,
                  errors: data.response?.data?.errors
               });
               throw data;
            }

            if (status >= 400 && status <= 599) {
               return data;
            }
            endRequest(true);
            await uploadFile(file, data?.putUrl);
            return data;
         }
         return await uploadFile(file, responseUrlSigned);
      }
   });
}
