import styled from "@emotion/styled";
import {
   FormControl,
   FormControlLabel,
   FormControlLabelProps,
   Radio as MuiRadio,
   RadioGroup,
   useRadioGroup
} from "@mui/material";
import { Typography } from "@uy3/web-components";
import { RadioProps, StyledFormControlLabelProps } from "./Radio.interface";

const StyledControlLabelRadio = styled((props: StyledFormControlLabelProps) => (
   <FormControlLabel
      sx={{
         ".MuiFormControlLabel-label": { fontSize: "1.5rem" }
      }}
      {...props}
   />
))(({ checked }) => ({ ".MuiFormControlLabel-label": checked && { color: "#222" } }));

export const ControlOptionRadio = (props: FormControlLabelProps): JSX.Element => {
   const radioGroupe = useRadioGroup();
   let checked = false;
   if (radioGroupe) {
      checked = radioGroupe.value === props.value;
   }
   return <StyledControlLabelRadio checked={checked} {...props} />;
};

export function Radio({ onChange, options, row, size, title, value }: RadioProps) {
   return (
      <FormControl>
         <Typography variant="h5" key={title}>
            {title}
         </Typography>
         <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="radio-buttons-group"
            row={row}
            value={value}
         >
            {options?.map((option, index) => (
               <ControlOptionRadio
                  control={
                     <MuiRadio
                        key={index}
                        size={size}
                        sx={{
                           "&.Mui-checked": { color: "#e86500" },
                           "& svg": { width: "2.3rem", height: "2.3rem" }
                        }}
                     />
                  }
                  value={option.value}
                  label={option.label}
                  disabled={option.disabled}
                  onChange={onChange}
               />
            ))}
         </RadioGroup>
      </FormControl>
   );
}
